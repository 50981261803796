import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useGenericManager, useOperationDialog } from 'spark-core-dx/hooks';
import { FieldControl, IconControl, Loading, DialogControl, ButtonControl } from 'spark-core-dx/components';
import { useCoreContext } from 'spark-core-dx/contexts';
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';
import ListOfUsers from './components/ListOfUsers';
import WizardFooter from './components/WizardFooter';
import { useWizardDomainContext } from '../WizardDomainProvider';
import { SystemTypes } from 'spark-core-dx/services';

const SetupUsers = () => {
    const itemService = new ItemServiceFactory()
    const coreContext = useCoreContext();
    const { openDialog, executeOperation, renderOperationContent, activeOperation, operationErrors, cancelOperation, operationSuccessMessage } = useOperationDialog(itemService.ItemServiceEnum.GraphUser);
    const { currentStep, setCurrentStep, updateOnboardingProcess, handleProgress, onboardingItem, setCachedGetGraphUserAndGroupByClient, cachedGetGraphUserAndGroupByClient } = useWizardDomainContext();
    const profileMgr = useGenericManager(itemService.ItemServiceEnum.Profile, itemService);
    const graphUserMgr = useGenericManager(itemService.ItemServiceEnum.GraphUser, itemService);
    const onboardingMgr = useGenericManager(itemService.ItemServiceEnum.Onboarding, itemService);
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [userImages, setUserImages] = useState([]);
    const [selectedUsersLeft, setSelectedUsersLeft] = useState([])
    const [selectedUsersRight, setSelectedUsersRight] = useState([])
    const [users, setUsers] = useState(null)
    const [groups, setGroups] = useState(null)
    const [userGroupMap, setUserGroupMap] = useState(null)
    const [openSettingUpUserDialog, setOpenSettingUpUserDialog] = useState(false)
    const [stagingDialog, setStagingDialog] = useState(false)
    const [error, setError] = useState(false)
    const handleOnSave = async (isContinue) => {
        setOpenSettingUpUserDialog(true)
        await profileMgr.RunOperation({
            operationName: "ProfileRoleGroupMap",
            item: {
                g: userGroupMap
            },
            supressAllErrors: true,
            isItem: false,
            queryParams: {
                clientid: onboardingItem.ClientId
            },
            isWarning: true
        }).then(async (r) => {
            if (r.Success) {
                setOpenSettingUpUserDialog(false)

                setCachedGetGraphUserAndGroupByClient({
                    ...cachedGetGraphUserAndGroupByClient,
                    UserGroupMap: _.cloneDeep(userGroupMap),
                })
                //TODO: isContinue needs to move to after the Staging dialog is complete.
                if (isContinue) {
                    await handleProgress(1, 1, 'page6', updatedPageValues => {
                        updateOnboardingProcess(currentStep + 1, updatedPageValues);
                    });

                    if (!onboardingItem.IsStageEnvCreated) {
                        setStagingDialog(true)
                    } else {
                        setCurrentStep(currentStep + 1)

                    }
                } else
                    await handleProgress(1, 1, 'page6', updatedPageValues => {
                        updateOnboardingProcess(currentStep, updatedPageValues);
                    });
            } else {
                setOpenSettingUpUserDialog(false)
                setError(r.MessageDetails.first())
            }
        })
    }

    useEffect(() => {
        if (cachedGetGraphUserAndGroupByClient?.users && cachedGetGraphUserAndGroupByClient?.groups && cachedGetGraphUserAndGroupByClient?.userGroupMap && !users && !groups && !userGroupMap) {
            setUsers(cachedGetGraphUserAndGroupByClient.users)
            setGroups(cachedGetGraphUserAndGroupByClient.groups)
            setUserGroupMap(cachedGetGraphUserAndGroupByClient.userGroupMap)
            setSelectedGroup(cachedGetGraphUserAndGroupByClient.groups.first().Value)
        } else {
            grabUsersAndGroups();
        }


        graphUserMgr.RunOperation({
            operationName: "GetGraphUserImage",
            queryParams: {
                clientid: onboardingItem.ClientId,
            }
        }).then(r => {
            if (r.Success) {
                setUserImages(r.Items)
                return r.Items
            }
        })

    }, [])


    const grabUsersAndGroups = async () => {

        const userResults = await graphUserMgr.RunOperation({
            operationName: "GetGraphUserAndGroupByClient",
            queryParams: {
                clientid: onboardingItem.ClientId,
            }, isWarning: true
        }).then(r => {
            if (r.Success) {
                let dti = r.Items.first()
                const transformedGroups = dti.GraphGroups.map(x => {
                    return {
                        Text: x.DisplayName,
                        Value: x.UniqueId
                    }
                })
                const tranformedUsers = dti.GraphUsers.map(x => {
                    return {
                        Name: x.DisplayName,
                        Email: x.Mail,
                        Value: x.UniqueId
                    }
                });
                setGroups(transformedGroups)
                setSelectedGroup(transformedGroups.first().Value)
                setUsers(tranformedUsers)
                setUserGroupMap(dti.UserGroupMap)
                return {
                    transformedGroups,
                    userGroupMap: dti.UserGroupMap,
                    tranformedUsers
                }

            }
        })

        setCachedGetGraphUserAndGroupByClient({
            users: userResults.tranformedUsers,
            groups: userResults.transformedGroups,
            userGroupMap: userResults.userGroupMap,
        })

    }
    const moveUserToLeft = () => {
        const copiedUserGroupMap = { ...userGroupMap }
        selectedUsersRight.forEach(x => {
            copiedUserGroupMap[x] = copiedUserGroupMap[x].filter(y => y !== selectedGroup)
        })
        setSelectedUsersRight([])
        setUserGroupMap(copiedUserGroupMap);
    }

    const moveUserToRight = () => {
        // setUserGroupMap()
        const copiedUserGroupMap = { ...userGroupMap }
        selectedUsersLeft.forEach(x => {
            if (copiedUserGroupMap[x]) {
                copiedUserGroupMap[x] = [...copiedUserGroupMap[x], selectedGroup]
            } else {
                copiedUserGroupMap[x] = [selectedGroup]
            }
        })
        setSelectedUsersLeft([])
        setUserGroupMap(copiedUserGroupMap);
    }

    const grabGroupNameFromValue = (value) => {
        const name = groups.find(x => x.Value === value).Text
        return name
    }

    const memoizedLeftUsers = useMemo(() => {
        return _.filter(users?.map(x => {
            return {
                ...x,
                Image: userImages.find(user => user.UserId == x.Value)?.Image ?? ""
            }
        }), (user) => !userGroupMap[user.Value]?.includes(selectedGroup))
    }, [users, selectedGroup, userImages, userGroupMap])

    const memoizedRightUsers = useMemo(() => {
        return _.filter(users?.map(x => {
            return {
                ...x,
                Image: userImages.find(user => user.UserId == x.Value)?.Image ?? ""
            }
        }), (user) => userGroupMap[user.Value]?.includes(selectedGroup))
    }, [users, selectedGroup, userImages, userGroupMap])

    const renderDialog = () => {
        if (openSettingUpUserDialog) {
            return (
                <DialogControl
                    className={'with-icon'}
                    openDialog={openSettingUpUserDialog}
                    title={"Setting Up Users"}
                    disableXButton={true}
                >
                    <div className="dialog-text">
                        <div style={{ marginBottom: '1rem' }}>
                            Please wait while users are being assigned to their designated groups.
                        </div>
                        <Loading type={'spinner-2'} />
                    </div>
                </DialogControl>
            )
        } else if (error) {
            return (
                <DialogControl
                    className={'with-icon'}
                    openDialog={error}
                    title={[<IconControl className={'error'} iconKey={'error'} />, "Error"]}
                    okText={"Return"}
                    disableCancel={true}
                    onOk={async () => {
                        setError(false)
                    }}
                >
                    <div className="dialog-text error">{error}</div>
                </DialogControl>
            )
        } else if (stagingDialog) {
            return (
                <DialogControl
                    openDialog={stagingDialog}
                    title={"Create Staging Environment"}
                    cancelText={'Skip'}
                    okText={"Create"}
                    onCancel={async () => {
                        setStagingDialog(false)
                        setCurrentStep(currentStep + 1)
                    }}
                    onOk={async () => {
                        const result = await onboardingMgr.RunOperation({ operationName: "CreateStagingEnvironment", item: onboardingItem, isItem: true })
                        setCurrentStep(currentStep + 1)
                    }}
                >
                    <div className="dialog-text">
                        A staging environment needs to be created to continue with onboarding. Click "Create" to proceed.
                        <br />
                        A ticket must also be created to setup the staging domain.
                    </div>
                </DialogControl>
            )
        }
    }

    return (<>
        {
            (users && groups) ?
                <>
                    {renderDialog()}
                    <DialogControl
                        openDialog={openDialog}
                        title={activeOperation?.DisplayName ?? activeOperation?.Name}
                        subTitle={activeOperation?.Details}
                        // disableXButton={true}
                        onCancel={cancelOperation}
                        onOk={async () => {
                            const result = await executeOperation()
                        }}
                        excludeCancel={!activeOperation}
                        okText={(activeOperation && operationErrors) ? "Retry" : "OK"}
                        disableCancel={operationSuccessMessage}
                    >
                        {renderOperationContent()}
                    </DialogControl >
                    <div className='wizard-content'>
                        <div className='setup-users-container'>
                            <div className='left-col'>
                                <div className="groups">
                                    <h4>Setup Groups</h4>
                                    <p>Verify Entra ID users and groups.</p>
                                    <FieldControl
                                        key={"selectgroup"}
                                        value={selectedGroup}
                                        className={'select-group'}
                                        onChange={(v) => {
                                            if (v)
                                                setSelectedGroup(v)
                                        }}
                                        fieldMetadata={{
                                            FieldName: "selectGroup",
                                            FieldType: SystemTypes.UIFieldType.SearchSelectList,
                                            DataJson: groups
                                        }} />
                                </div>

                                <div className="setup-users">
                                    <div className='list-container'>
                                        <h4>User List</h4>
                                        <ListOfUsers
                                            onSelectAll={(v) => {
                                                setSelectedUsersLeft(v)
                                            }}
                                            disable={!selectedGroup}
                                            value={selectedUsersLeft}
                                            datajson={memoizedLeftUsers}
                                            onChange={setSelectedUsersLeft}
                                        />
                                    </div>
                                    <div className="switch">
                                        <div className='switch-button'>
                                            <IconControl width={'1.5rem'} height={'1.5rem'} onClick={moveUserToLeft} iconKey={'arrowleft'} />
                                        </div>
                                        <div className='switch-button'>
                                            <IconControl width={'1.5rem'} height={'1.5rem'} onClick={moveUserToRight} iconKey={'arrowright'} />
                                        </div>
                                    </div>
                                    <div className='list-container'>
                                        <h4>{grabGroupNameFromValue(selectedGroup)} User List</h4>
                                        <ListOfUsers
                                            onSelectAll={(v) => {
                                                setSelectedUsersRight(v)
                                            }}
                                            disable={!selectedGroup}
                                            value={selectedUsersRight}
                                            datajson={memoizedRightUsers}
                                            onChange={setSelectedUsersRight}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className='right-col'>
                                <h4>Instructions</h4>
                                <p>Export the user list using the “Export User List” button.</p>
                                <div>
                                    <ButtonControl className='export-button' minWidth={'9rem'} value={'Export User List'} type={'okay'} onClick={async () => {
                                        await graphUserMgr.RunOperation({
                                            operationName: "UserExport",
                                            queryParams: {
                                                clientid: onboardingItem.ClientId,
                                                UserExportType: 'UserGroups'
                                            }
                                        })
                                    }} />
                                </div>
                                <p>A meeting will need to be set up with the Franchisee Champion to explain the spreadsheet and the desired outcome. After the call a follow-up email will need to be sent with the spreadsheet attached for the Franchisee Champion to complete.</p>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className="wizard-content">
                    <Loading type={'spinner-2'} />
                </div>}
        <WizardFooter
            disableAll={!users && !groups}
            prevBtnOnClick={() => setCurrentStep(4)}
            showPrevBtn={true}
            primaryBtnOnClick={async () => await handleOnSave(true)}
            primaryBtnText={"Save & Continue"}
            showSecBtn={true}
            secBtnOnClick={async () => await handleOnSave()}
            secBtnText={"Save"}
        />

    </>
    );
};

export default SetupUsers;